import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import 'bootstrap/dist/css/bootstrap.min.css';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import NewPassword from './components/ForgotPassword/NewPassword';
import AccountCreatedModal from './components/AccountCreated/AccountCreated';

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        {/* Notice the :token parameter in the route */}
        <Route path="/reset/password/:token" element={<NewPassword />} />
        <Route path="/AccountCreated" element={<AccountCreatedModal />} />
        <Route path="/lawyer/verify/:token" element={<AccountCreatedModal />} />
      </Routes>
    </div>
  );
};

export default App;
