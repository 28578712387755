import React, { useState } from "react";
import axios from "axios"; // Import axios for API calls
import "./ForgotPassword.css";
import LockIcon from "../../Assets/Group.png";
import arrowIcon from "../../Assets/chevron-left.png";
import checkEmailIcon from "../../Assets/EmailIcon.png";
import editIcon from "../../Assets/Edit.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); 

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsEmailValid(validateEmail(inputEmail));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid) {
      try {
        const response = await axios.post("https://myapp.maplelawpro.com/api/reset/password", {
          email,
        });

        if (response.status === 200) {
          setIsSubmitted(true); 
        } else {
          console.error("Error:", response.data);
        }
      } catch (error) {
        console.error("API Error:", error.response || error.message);
      }
    }
  };

  if (isSubmitted) {
    return (
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <div
          className="card text-center p-4 shadow"
          style={{
            maxWidth: "500px",
            width: "100%",
            borderRadius: "20px",
            border: "1px solid #1F9AA24D",
          }}
        >
          <div className="card-body">
            <img
              src={checkEmailIcon}
              alt="Email Icon"
              className="mb-3"
              style={{ maxWidth: "100px" }}
            />

            <h3 className="card-title">Check Your Email</h3>

            <p className="card-text">
              We’ve sent password reset instructions to:
              <br />
              <span className="email-with-edit">
                <strong>{email}</strong>
                <img src={editIcon} alt="Edit" className="edit-icon" />
              </span>
            </p>

            <a
              href="https://mail.google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-lg w-100"
            >
              Open Email
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div
        className="card text-center p-4 shadow"
        style={{
          maxWidth: "500px",
          width: "100%",
          borderRadius: "20px",
          border: "1px solid #1F9AA24D",
        }}
      >
        <div className="card-body">
          <img
            src={LockIcon}
            alt="Lock Icon"
            className="mb-3"
            style={{ maxWidth: "100px" }}
          />

          <h3 className="card-title">Forgot Password?</h3>
          <p className="card-text">
            Enter your email address and we will send you instructions to reset
            your password.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                placeholder="Enter Email Address*"
                className="emailInput form-input"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-lg w-100"
              disabled={!isEmailValid}
            >
              Continue
            </button>
          </form>

          <a href="/" className="d-block mt-3 back-to-login">
            <img
              src={arrowIcon}
              alt="Arrow Icon"
              className="arrow-icon"
              style={{ height: "20px", width: "20px" }}
            />
            Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
