import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams, useNavigate } from "react-router-dom"; // Import useParams and useNavigate
import * as Yup from "yup";
import "./ForgotPassword.css";
import LockIcon from "../../Assets/Group.png";
import eyeOpenIcon from "../../Assets/Show.png";
import eyeClosedIcon from "../../Assets/Hide.png";
import green from "../../Assets/green.png";
import red from "../../Assets/red.png";
import Danger from "../../Assets/Danger Circle.png";

const passwordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Must be at least 8 characters long")
    .matches(/\d/, "Must contain at least one numeric character")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Must contain at least one special character (example: !, #, @)"
    )
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const NewPassword = () => {
  const { token } = useParams(); 
  const navigate = useNavigate(); 
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === "newPassword") {
      setPasswordVisible(!passwordVisible);
    } else {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  useEffect(()=>{
    const response=fetch( ` https://myapp.maplelawpro.com/api/reset/password/${token}`,{
      method: "POST"
    })
    if(response.status){
     console.log("TOken Verified")
    }else{
      console.log("Error",response.message)
    }
  })
  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div
        className="card text-center p-4 shadow"
        style={{
          maxWidth: "500px",
          width: "100%",
          borderRadius: "20px",
          border: "1px solid #1F9AA24D",
        }}
      >
        <div className="card-body">
          <img
            src={LockIcon}
            alt="Lock Icon"
            className="mb-3"
            style={{ maxWidth: "100px" }}
          />
          <h3 className="card-title">Create New Password</h3>
          <p className="card-text">Create a new password below.</p>

          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validationSchema={passwordSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setIsSubmitting(true); 
              try {
                const response = await fetch(
                  ` https://myapp.maplelawpro.com/api/reset/password/${token}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      password: values.newPassword,
                      password_confirmation: values.confirmPassword,
                    }),
                  }
                );

                if (response.ok) {
                  alert("Password successfully reset!");
                  resetForm();
                  navigate("/login");
                } else {
                  const errorData = await response.json();
                  alert(errorData.message || "Error resetting password. Please try again.");
                }
              } catch (error) {
                console.error("Error:", error);
                alert("An error occurred. Please try again.");
              } finally {
                setIsSubmitting(false);
                setSubmitting(false);
              }
            }}
          >
            {({ errors, touched, isValid }) => (
              <Form>
                <div className="position-relative mb-3">
                  <Field
                    name="newPassword"
                    type={passwordVisible ? "text" : "password"}
                    placeholder="New Password*"
                    className={`form-input ${
                      touched.newPassword && errors.newPassword
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <img
                    src={passwordVisible ? eyeOpenIcon : eyeClosedIcon}
                    alt="Toggle password visibility"
                    className="password-toggle-icon"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "34%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      height: "19px",
                      width: "23px",
                    }}
                    onClick={() => togglePasswordVisibility("newPassword")}
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="error-message"
                  />
                </div>

                {touched.newPassword && (
                  <div className="password-requirements mb-3 text-start">
                    <p className="requirement">
                      <img
                        src={
                          errors.newPassword &&
                          errors.newPassword.includes("8 characters")
                            ? red
                            : green
                        }
                        style={{
                          width: "17px",
                          height: "17px",
                          marginRight: "8px",
                          marginLeft: "8px",
                        }}
                        alt="Status Icon"
                      />
                      <span style={{ fontSize: "12px" }}>
                        Must be at least 8 characters long
                      </span>
                    </p>
                    <p className="requirement">
                      <img
                        src={
                          errors.newPassword &&
                          errors.newPassword.includes("numeric")
                            ? red
                            : green
                        }
                        style={{
                          width: "17px",
                          height: "17px",
                          marginRight: "8px",
                          marginLeft: "8px",
                        }}
                        alt="Status Icon"
                      />
                      <span style={{ fontSize: "12px" }}>
                        Must contain at least one numeric character
                      </span>
                    </p>
                    <p className="requirement">
                      <img
                        src={
                          errors.newPassword &&
                          errors.newPassword.includes("special character")
                            ? red
                            : green
                        }
                        style={{
                          width: "17px",
                          height: "17px",
                          marginRight: "8px",
                          marginLeft: "8px",
                        }}
                        alt="Status Icon"
                      />
                      <span style={{ fontSize: "12px" }}>
                        Must contain at least one special character (e.g., !, #,
                        @)
                      </span>
                    </p>
                  </div>
                )}

                <div className="position-relative mb-3">
                  <Field
                    name="confirmPassword"
                    type={confirmPasswordVisible ? "text" : "password"}
                    placeholder="Confirm Password*"
                    className={`form-input ${
                      touched.confirmPassword && errors.confirmPassword
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <img
                    src={confirmPasswordVisible ? eyeOpenIcon : eyeClosedIcon}
                    alt="Toggle confirm password visibility"
                    className="password-toggle-icon"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "34%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      height: "19px",
                      width: "23px",
                    }}
                    onClick={() =>
                      togglePasswordVisibility("confirmPassword")
                    }
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error-message"
                  />
                </div>

                {/* Error message for password match */}
                {errors.confirmPassword && (
                  <div className="error-message-top">
                    <div style={{ display: "flex", padding: "8px" }}>
                      <img
                        src={Danger}
                        style={{
                          width: "17px",
                          height: "17px",
                          marginRight: "8px",
                          marginLeft: "8px",
                        }}
                        alt="Error Icon"
                      />
                      <span style={{ fontSize: "14px", paddingTop: "3px" }}>
                        {errors.confirmPassword}
                      </span>
                    </div>
                  </div>
                )}
                
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-100"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? "Resetting Password..." : "Reset Password"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
