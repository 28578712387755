import React from "react";
// import { Check } from 'lucide-react';
import "./Accountcreated.css";
import Time from "../../Assets/Time Circle.png";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AccountCreatedModal = () => {
  const { token } = useParams();
  useEffect(() => {
    const response = fetch(
      ` https://myapp.maplelawpro.com/api/lawyer/verify/${token}`
    );
    if (response.status) {
      console.log("TOken Verified", response);
    } else {
      console.log("Error", response.message);
    }
  });
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
    >
      <div className="Account text-center p-4 bg-white rounded shadow-sm">
        <div className="mb-4">
          <div>
            <img
              src={Time}
              style={{
                width: "98px",
                height: "98px",
                marginRight: "8px",
                marginLeft: "8px",
              }}
              alt="Logo"
            />
          </div>
        </div>

        <h2 className="mb-3">Account created successfully</h2>
        {token ? (
          <p className="text-muted mb-4">
            Please check your email for the login credentials we just sent you.
          </p>
        ) : (
          <p className="text-muted mb-4">
            Please Verify your email to get the login credentials .
          </p>
        )}
        <button
          className="btn no-hover-effect text-white px-4 py-2"
          onClick={() => (window.location.href = "/")}
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default AccountCreatedModal;
