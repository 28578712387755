import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./signup.css";
import { useNavigate } from "react-router-dom";

import GroupImg from "../../Assets/Group3.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { ButtonGroup } from "react-bootstrap";
import Group21 from "../../Assets/Group 21.png";
import Group22 from "../../Assets/Group 22.png";
import creditCard from "../../Assets/Credit card.png";
import wallet from "../../Assets/Wallet_alt.png";
import Group26 from "../../Assets/Group 26.png";
import Maskgroup from "../../Assets/Mask group (2).png";
import Danger from "../../Assets/Danger Circle.png";
import Paypal from "../../Assets/image 11.png";

const SignUpPage = () => {
  const [step, setStep] = useState(1);
  const [selectedMethod, setSelectedMethod] = useState("creditCard");
  const [jurisdictions, setJurisdictions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Replace {{Url}} with your actual API base URL
    const apiUrl = "https://myapp.maplelawpro.com/api/jurisdiction/list";

    // Fetch the list of jurisdictions
    axios
      .get(apiUrl)
      .then((response) => {
        console.log(response.data.data);
        setJurisdictions(response.data.data); // Set the data received from the API
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((err) => {
        setError(err.message); // Handle errors if any
        setLoading(false);
      });
  }, []);
  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const stepTitle = () => {
    if (step === 1) return "Personal Details";
    if (step === 2) return "Lawyer and Account Type";
    if (step === 3) return "Payment and Billing Details";
    if (step === 4) return "Payment and Billing Details";
    return "";
  };

  const paymentMethods = [
    { name: "Credit Card", value: "creditCard", img: creditCard },
    { name: "Digital Wallet", value: "digitalWallet", img: wallet },
    { name: "Direct Debit", value: "directDebit", img: Group22 },
    { name: "UPI", value: "upi", img: Group21 },
  ];
  const progressPercentage = (step / 4) * 100;
  // Validation schemas for each step
  const Navigate =useNavigate();
  const validationSchemas = [
    // Step 1: Personal Details
    Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      firmName: Yup.string().required("Firm/Office Name is required"),
      address: Yup.string().required("Address is required"),
      unit: Yup.string().required("Unit is required"),
      streetNo: Yup.string().required("Unit is required"),
      street: Yup.string().required("Street is required"),
      city: Yup.string().required("City is required"),
      provinceState: Yup.string().required("Province or State is required"),
      postalCode: Yup.string().required("Postal Code is required"),
      phone: Yup.string().required("Phone is required"),
      fax: Yup.string().required("Fax is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      privacyPolicy: Yup.boolean().oneOf(
        [true],
        "You must agree to the privacy policy and terms"
      ),
    }),
    // Step 2: Lawyer and Account Type
    Yup.object().shape({
      lawyerName: Yup.string().required("Lawyer Name is required"),
      licenseNumber: Yup.string().required("License number is required"),
      jurisdictionType: Yup.string().required("Jurisdiction Type is required"),
      accountType: Yup.string().required("Account Type is required"),
    }),
    // Step 3: Payment and Billing Details
    Yup.object().shape({
      // Conditionally include these fields when selectedMethod is "creditCard"
      ...(selectedMethod === "creditCard" && {
        cardholderName: Yup.string().required("Cardholder Name is required"),
        cardNumber: Yup.string().required("Card number is required"),
      }),
      billingFirstName: Yup.string().required("Billing First Name is required"),
      billingLastName: Yup.string().required("Billing Last Name is required"),
      billingPhone: Yup.string().required("Billing Phone is required"),
      billingEmail: Yup.string()
        .email("Invalid email")
        .required("Billing Email is required"),
    }),

    // Step 4: Authorization Form
    Yup.object().shape({
      authorizationForm: Yup.mixed().nullable(),
    }),
  ];

  const handleSubmit = async (values) => {
    try {
      const postData = {
        last_name: values.lastName,
        first_name: values.firstName,
        middle_name: "",
        firm_office_name: values.firmName,
        address: {
          street_no: values.streetNo,
          street_name: values.street,
          unit_no: values.unit,
          city: values.city,
          province: values.provinceState, 
          postal_code: values.postalCode,
        },
        phone: values.phone,
        fax: values.fax,
        email: values.email,
        lawyer_name: values.lawyerName,
        lawyer_license: values.licenseNumber,
        jurisdiction_id: values.jurisdictionType,
        billing_contact: {
          first_name: values.billingFirstName,
          last_name: values.billingLastName,
          middle_name: "",
          phone: values.billingPhone,
          email: values.billingEmail,
        },
        account_type: values.accountType,
        payment_method: selectedMethod,
        form_upload: values.authorizationForm || "",
      };

      const response = await axios.post(
        "https://myapp.maplelawpro.com/api/lawyer-registration",
        postData
      );
        console.log("Form Submitted:", response.data);
        
        Navigate("/AccountCreated", { state: { success: true, data: response.data } });  
      
    } catch (error) {
      console.error("Error posting data:", error);
      Navigate("/AccountCreated", { state: { success: false, error: error.message } });
    }
  };

  const ErrorSummary = ({ errors, touched }) => {
    const touchedErrors = Object.keys(errors).filter((key) => touched[key]);
    if (touchedErrors.length === 0) return null;

    return (
      <div className="error-message-top">
        {touchedErrors.map((key) => (
          <div key={key} className="error-item">
            <span className="error-icon">
              <img
                src={Danger}
                style={{ width: "17px", height: "17px", marginRight: "8px" }}
                alt="Error"
              />
            </span>
            <span>{errors[key]}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="login-page d-flex">
      <div className="left-section d-flex flex-column align-items-center justify-content-center">
        <div className="text-center">
          <div className="left-banner">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={GroupImg}
                style={{ width: "65px", marginRight: "8px" }}
                alt="Logo"
              />
              <h2>Logo</h2>
            </span>
            <h1>Welcome to Legal Tasks Management and Monitoring Software</h1>
            <p>
              Your centralized hub for managing legal tasks, tracking case
              progress, and optimizing workflows.
            </p>
          </div>
        </div>
      </div>

      <div className="right-section d-flex align-items-center justify-content-center">
        <div className="signup-form">
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <h2 className="signup-title">Sign Up</h2>
              <p className="signup-subtitle">Create your account in 4 steps</p>

              <div className="step-indicator">
                <div className="step-circle">
                  <span className="step-number">{step}</span>
                  <div
                    className="progress-border"
                    style={{
                      clipPath: `inset(0 ${100 - progressPercentage}% 0 0)`,
                      borderColor: step === 4 ? "#2CB875" : "#2CB875",
                    }}
                  />
                </div>
                <div>
                  <p className="step-text">Step {step}</p>
                  <p className="step-title">{stepTitle()}</p>
                </div>
              </div>
            </div>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                firmName: "",
                address: "",
                unit: "",
                streetNo: "",
                street: "",
                city: "",
                provinceState: "",
                postalCode: "",
                phone: "",
                fax: "",
                email: "",
                privacyPolicy: false,
                lawyerName: "",
                licenseNumber: "",
                jurisdictionType: "",
                accountType: "",
                cardholderName: "",
                cardNumber: "",
                billingFirstName: "",
                billingLastName: "",
                billingPhone: "",
                billingEmail: "",
                authorizationForm: null,
              }}
              validationSchema={validationSchemas[step - 1]}
              onSubmit={(values, { setSubmitting }) => {
                if (step < 4) {
                  nextStep();
                } else {
                  handleSubmit(values);
                }
                setSubmitting(false);
              }}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="form-box scrollable">
                    <ErrorSummary errors={errors} touched={touched} />
                    {step === 1 && (
                      <>
                        <div className="input-grid">
                          <Field
                            name="firstName"
                            type="text"
                            placeholder="First Name*"
                            className={`form-input ${
                              touched.firstName && errors.firstName
                                ? "input-error"
                                : ""
                            }`}
                          />
                          <Field
                            name="lastName"
                            type="text"
                            placeholder="Last Name*"
                            className={`form-input ${
                              touched.lastName && errors.lastName
                                ? "input-error"
                                : ""
                            }`}
                          />
                        </div>
                        <Field
                          name="firmName"
                          type="text"
                          placeholder="Firm/Office Name*"
                          className={`form-input ${
                            touched.firmName && errors.firmName
                              ? "input-error"
                              : ""
                          }`}
                        />
                        <Field
                          name="address"
                          type="text"
                          placeholder="Address*"
                          className={`form-input ${
                            touched.address && errors.address
                              ? "input-error"
                              : ""
                          }`}
                        />
                        <div className="input-grid">
                          <Field
                            name="unit"
                            type="text"
                            placeholder="Unit*"
                            className={`form-input ${
                              touched.unit && errors.unit ? "input-error" : ""
                            }`}
                          />
                          <Field
                            name="streetNo"
                            type="text"
                            placeholder="Street No*"
                            className={`form-input ${
                              touched.streetNo && errors.streetNo
                                ? "input-error"
                                : ""
                            }`}
                          />
                        </div>
                        <div className="input-grid">
                          <Field
                            name="street"
                            type="text"
                            placeholder="Street*"
                            className={`form-input ${
                              touched.street && errors.street
                                ? "input-error"
                                : ""
                            }`}
                          />
                          <Field
                            name="city"
                            type="text"
                            placeholder="City*"
                            className={`form-input ${
                              touched.city && errors.city ? "input-error" : ""
                            }`}
                          />
                        </div>
                        <div className="input-grid">
                          <Field
                            name="provinceState"
                            type="text"
                            placeholder="Province or State*"
                            className={`form-input ${
                              touched.provinceState && errors.provinceState
                                ? "input-error"
                                : ""
                            }`}
                          />
                          <Field
                            name="postalCode"
                            type="text"
                            placeholder="Postal Code*"
                            className={`form-input ${
                              touched.postalCode && errors.postalCode
                                ? "input-error"
                                : ""
                            }`}
                          />
                        </div>
                        <div className="input-grid">
                          <Field
                            name="phone"
                            type="text"
                            placeholder="Phone*"
                            className={`form-input ${
                              touched.phone && errors.phone ? "input-error" : ""
                            }`}
                          />
                          <Field
                            name="fax"
                            type="text"
                            placeholder="Fax*"
                            className={`form-input ${
                              touched.fax && errors.fax ? "input-error" : ""
                            }`}
                          />
                        </div>
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email*"
                          className={`form-input ${
                            touched.email && errors.email ? "input-error" : ""
                          }`}
                        />
                        <div className="checkbox-container">
                          <Field
                            type="checkbox"
                            id="privacyPolicy"
                            name="privacyPolicy"
                          />
                          <label htmlFor="privacyPolicy" className="chkPolicy">
                            I agree to the{" "}
                            <a href="#" style={{ textDecoration: "none" }}>
                              privacy policy
                            </a>{" "}
                            and{" "}
                            <a href="#" style={{ textDecoration: "none" }}>
                              terms and conditions
                            </a>
                            .
                          </label>
                        </div>
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          Lawyer Details
                        </span>
                        <Field
                          name="lawyerName"
                          type="text"
                          placeholder="Lawyer Name*"
                          className={`form-input ${
                            touched.lawyerName && errors.lawyerName
                              ? "input-error"
                              : ""
                          }`}
                        />
                        <Field
                          name="licenseNumber"
                          type="text"
                          placeholder="License number*"
                          className={`form-input ${
                            touched.licenseNumber && errors.licenseNumber
                              ? "input-error"
                              : ""
                          }`}
                        />
                        <Field
                          as="select"
                          name="jurisdictionType"
                          className={`form-input ${
                            touched.jurisdictionType && errors.jurisdictionType
                              ? "input-error"
                              : ""
                          }`}
                        >
                          <option value="">Select Jurisdiction Type</option>
                          {jurisdictions.length > 0 &&
                            jurisdictions.map((jurisdiction) => (
                              <option
                                key={jurisdiction.id}
                                value={jurisdiction.id}
                              >
                                {jurisdiction.name}
                              </option>
                            ))}
                        </Field>

                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          Account Type
                        </span>
                        <Field
                          as="select"
                          name="accountType"
                          className={`form-input ${
                            touched.accountType && errors.accountType
                              ? "input-error"
                              : ""
                          }`}
                        >
                          <option value="">Select Account Type</option>
                          <option value="Law Firm">Law Firm</option>
                          <option value="Paralegal">Paralegal</option>
                          <option value="Other">Other</option>
                        </Field>
                      </>
                    )}

                    {step === 3 && (
                      <>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          Payment Method
                        </span>
                        <ButtonGroup
                          toggle
                          className="mainDiv d-flex justify-content-between"
                        >
                          {paymentMethods.map((method, idx) => (
                            <div
                              key={idx}
                              onClick={() => setSelectedMethod(method.value)}
                              className={`payment-option ${
                                selectedMethod === method.value
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <img
                                src={method.img}
                                alt={method.name}
                                className="mb-2"
                                style={{ width: "50px", height: "50px" }}
                              />
                              <div className="methodname">{method.name}</div>
                            </div>
                          ))}
                        </ButtonGroup>
                        {selectedMethod === "creditCard" && (
                          <>
                            <Field
                              name="cardholderName"
                              type="text"
                              placeholder="Cardholder Name*"
                              className={`form-input ${
                                touched.cardholderName && errors.cardholderName
                                  ? "input-error"
                                  : ""
                              }`}
                            />
                            <Field
                              name="cardNumber"
                              type="text"
                              placeholder="Card number*"
                              className={`form-input ${
                                touched.cardNumber && errors.cardNumber
                                  ? "input-error"
                                  : ""
                              }`}
                            />
                          </>
                        )}

                        {selectedMethod === "directDebit" && (
                          <>
                            <Field
                              name="accountNumber"
                              type="text"
                              placeholder="Account Number*"
                              className={`form-input ${
                                touched.accountNumber && errors.accountNumber
                                  ? "input-error"
                                  : ""
                              }`}
                            />
                            <Field
                              name="routingNumber"
                              type="text"
                              placeholder="Routing Number*"
                              className={`form-input ${
                                touched.routingNumber && errors.routingNumber
                                  ? "input-error"
                                  : ""
                              }`}
                            />
                          </>
                        )}

                        {selectedMethod === "digitalWallet" && (
                          <div className="digital-wallet-option">
                            <div>
                              <img
                                src={Paypal}
                                style={{
                                  width: "55px",
                                  height: "55px",
                                  marginRight: "8px",
                                }}
                                alt="Error"
                              />
                              <span
                                style={{ fontSize: "18px", fontWeight: "500" }}
                              >
                                Paypal
                              </span>
                            </div>
                            <span className="connect-button">Connect</span>
                          </div>
                        )}

                        {selectedMethod === "upi" && (
                          <div
                            className="upi-input-container"
                            style={{ position: "relative" }}
                          >
                            <Field
                              name="upiId"
                              type="text"
                              placeholder="Enter UPI ID"
                              className={`form-input ${
                                touched.upiId && errors.upiId
                                  ? "input-error"
                                  : ""
                              }`}
                              style={{ paddingRight: "40px" }} // Add space for the image
                            />
                            <img
                              src={Group21}
                              alt="UPI"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "45%",
                                transform: "translateY(-50%)",
                                width: "42px",
                                height: "40px",
                              }}
                            />
                          </div>
                        )}
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          Contact for Billing
                        </span>
                        <div className="input-grid">
                          <Field
                            name="billingFirstName"
                            type="text"
                            placeholder="First Name*"
                            className={`form-input ${
                              touched.billingFirstName &&
                              errors.billingFirstName
                                ? "input-error"
                                : ""
                            }`}
                          />
                          <Field
                            name="billingLastName"
                            type="text"
                            placeholder="Last Name*"
                            className={`form-input ${
                              touched.billingLastName && errors.billingLastName
                                ? "input-error"
                                : ""
                            }`}
                          />
                        </div>
                        <Field
                          name="billingPhone"
                          type="text"
                          placeholder="Phone*"
                          className={`form-input ${
                            touched.billingPhone && errors.billingPhone
                              ? "input-error"
                              : ""
                          }`}
                        />
                        <Field
                          name="billingEmail"
                          type="email"
                          placeholder="Email*"
                          className={`form-input ${
                            touched.billingEmail && errors.billingEmail
                              ? "input-error"
                              : ""
                          }`}
                        />
                      </>
                    )}

                    {step === 4 && (
                      <div className="file-upload">
                        <div className="download-section">
                          <a
                            href="path-to-your-form.pdf"
                            download
                            className="download-button"
                          >
                            <img
                              src={Group26}
                              alt="Form Icon"
                              className="icon"
                            />
                            Download The Authorization Form
                          </a>
                        </div>
                        <div className="upload-box">
                          <label htmlFor="fileUpload" className="file-label">
                            <div className="">
                              <img
                                src={Maskgroup}
                                alt="Upload Icon"
                                className="iconImg"
                              />
                            </div>
                            <div>
                              Upload or drag and drop your form file here.
                            </div>
                          </label>
                          <input
                            id="fileUpload"
                            name="authorizationForm"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(event) => {
                              setFieldValue(
                                "authorizationForm",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="authorizationForm"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    )}
                  </div>

                  <div className="button-container">
                    <button
                      type="button"
                      onClick={prevStep}
                      className="Back-button btn btn-secondary"
                      disabled={step === 1}
                    >
                      Back
                    </button>

                    <button
                      type="submit"
                      className="next-button btn btn-success"
                      disabled={isSubmitting}
                    >
                      {step === 4 ? "Submit" : "Next"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <p className="login-link">
              Already have an account?{" "}
              <a href="/" style={{ color: "#1F9AA2" }}>
                Log in
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
